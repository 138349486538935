import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import HubspotForm from 'react-hubspot-form';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Banner from '../components/banner';
import Modules from '../components/modules';
import { htmlDecode } from '../Utils';
import Link from '../components/link';

const ResourceTemplate = ({ data, pageContext }) => {
  const seoTitle = data.strapiResource.seo
    ? data.strapiResource.seo.seoTitle
    : data.strapiResource.title;
  const seoDesc = data.strapiResource.seo
    ? data.strapiResource.seo.seoDescription
    : '';
  const term = data.strapiResource.resource_categories
    ? data.strapiResource.resource_categories[0]
    : null;
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  const featuredImageUrl =
    data.strapiResource?.featuredImage?.url ??
    'https://dlyog079eaher.cloudfront.net/uploads/resources_hero_f7e0fa569f.jpg';

  let formId = null;
  if (data.strapiResource.form) {
    const split1 = data.strapiResource.form.split('formId:');
    const split2 = split1[1].split('\n');
    formId = split2[0].replaceAll('"', '');
  }

  useEffect(() => {
    console.log(
      JSON.stringify(data.strapiResource?.featuredImage?.url, null, 2)
    );
  }, [data.strapiResource?.featuredImage?.url]);

  return (
    <Layout
      classNames={`resource-template-default single single-resource ${data.strapiResource.id}`}
    >
      <Seo
        title={data.strapiResource.title}
        seoTitle={seoTitle}
        seoDescription={seoDesc}
        featImage={featuredImageUrl}
      />
      {term && term.slug === 'webinars' && (
        <Helmet>
          <script
            charset="ISO-8859-1"
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>
        </Helmet>
      )}
      {data.strapiResource?.resource_categories?.[0]?.slug !== 'case-study' && (
        <div id="banner" className="banner videos">
          {data.strapiResource?.bannerImage && (
            <div
              className="col-12 banner-bg-image ph0 small gradient-map"
              style={{
                backgroundImage: `url("${
                  data.strapiResource.bannerImage.url.includes(cdnUrl)
                    ? data.strapiResource.bannerImage.url
                    : cdnUrl + data.strapiResource.bannerImage.url
                }")`,
                opacity: 1,
              }}
            />
          )}
          {!data.strapiResource?.bannerImage && (
            <div
              className="col-12 banner-bg-image ph0 small gradient-map"
              style={{
                backgroundImage: `url(${featuredImageUrl})`,
                opacity: 1,
              }}
            />
          )}
          <div className="banner-overlay blue-overlay" />
          <div className="banner-inside videos">
            <div className="col-12 banner-text videos text-center blue-overlay">
              {data.strapiResource.title && (
                <h1 className="tcw">{htmlDecode(data.strapiResource.title)}</h1>
              )}
            </div>
          </div>
        </div>
      )}
      {data.strapiResource?.resource_categories?.[0]?.slug === 'case-study' && (
        <>
          <section className="banner-wrap">
            <div id="banner" className="banner case-study medium">
              {data.strapiResource?.featuredImage && (
                <div
                  className="col-12 banner-bg-image ph0 medium small gradient-map"
                  style={{
                    backgroundImage: `url("${
                      data.strapiResource.featuredImage.url.includes(cdnUrl)
                        ? data.strapiResource.featuredImage.url
                        : cdnUrl + data.strapiResource.featuredImage.url
                    }")`,
                    opacity: 1,
                  }}
                />
              )}
              {!data.strapiResource?.featuredImage && (
                <div
                  className="col-12 banner-bg-image ph0 medium small gradient-map"
                  style={{
                    backgroundImage: `url("https://dlyog079eaher.cloudfront.net/uploads/resources_hero_f7e0fa569f.jpg")`,
                    opacity: 1,
                  }}
                />
              )}
              <div className="banner-overlay blue-overlay" />
              <div className="banner-inside case-study">
                <div className="col-12 banner-text case-study text-center blue-overlay">
                  <span className="case-study-label tcw">Case Study</span>
                  {data.strapiResource.title && (
                    <h1 className="tcw">
                      {htmlDecode(data.strapiResource.title)}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </section>
          {data.strapiResource.keyTakeaways && (
            <div className="sub-hero-block case-study-sub-hero-block">
              <div className="container">
                <div className="row jcc">
                  <div className="col-12">
                    <div className="bs1 br3 bfc key-takeaways subhero sub134">
                      <div className="row">
                        <div className="col-12 col-md-3 takeaway-title-wrap">
                          <span className="takeaway-title">Key Takeaways</span>
                        </div>
                        <div className="col-12 col-md-9 takeaway-points">
                          <ul>
                            {data.strapiResource.keyTakeaways &&
                              data.strapiResource.keyTakeaways.map(item => (
                                <li>{item.takeaway}</li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <section className="content-wrap">
        <div className="source">
          {data.strapiResource.caseStudyModules && (
            <Modules modules={data.strapiResource.caseStudyModules} />
          )}

          {data.strapiResource.caseStudyModules.length === 0 && (
            <div className="container">
              <div className="row">
                <div
                  className={`col-12 col-lg-6 ${
                    term && term.slug === 'videos' && !formId
                      ? 'col-lg-8 offset-lg-2'
                      : ''
                  }`}
                >
                  <div
                    className={`mv5 article-body ${
                      term && term.slug === 'videos' ? 'video-resource' : ''
                    }`}
                  >
                    {data.strapiResource.resourceBanner &&
                      data.strapiResource.resourceBanner?.resourceSubTitle && (
                        <h1 className="module-header-small">
                          {htmlDecode(
                            data.strapiResource.resourceBanner.resourceSubTitle
                          )}
                        </h1>
                      )}
                    {(!data.strapiResource.resourceBanner ||
                      !data.strapiResource.resourceBanner
                        ?.resourceSubTitle) && (
                      <span className="module-header-small">
                        {htmlDecode(data.strapiResource.title)}
                      </span>
                    )}
                    {data.strapiResource.content && (
                      <div
                        className="copy"
                        dangerouslySetInnerHTML={{
                          __html: data.strapiResource.content,
                        }}
                      />
                    )}
                  </div>
                </div>
                {data.strapiResource.gated && formId && (
                  <div className="col-12 col-lg-6">
                    <div className="form_with_content_block__form-wrap resource-form subform sub9">
                      {data.strapiResource.formTitle && (
                        <h2 className="form_with_content_block__form-title ft8 bg6">
                          {htmlDecode(data.strapiResource.formTitle)}
                        </h2>
                      )}
                      <div className="pt3 ph6 pb5 tac">
                        {data.strapiResource.form && (
                          <HubspotForm
                            portalId="2718424"
                            css=""
                            cssClass="hbspt-form"
                            region="na1"
                            formId={formId}
                            loading={
                              <div className="form-loading">
                                <h3>Loading...</h3>
                              </div>
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!data.strapiResource.gated &&
                  data.strapiResource.downloadButtonLink && (
                    <div className="col-12 col-lg-6">
                      <div className="form_with_content_block__form-wrap resource-form subform sub9">
                        {data.strapiResource.formTitle && (
                          <h2 className="form_with_content_block__form-title ft8 bg6">
                            {htmlDecode(data.strapiResource.formTitle)}
                          </h2>
                        )}
                        <div className="pv3 ph3 ft5 tac">
                          <Link
                            href={data.strapiResource.downloadButtonLink}
                            className="button"
                            target={`${
                              data.strapiResource.openNewTab
                                ? '_blank'
                                : '_self'
                            }`}
                          >
                            {data.strapiResource.downloadButtonText}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </section>
      {term && term.slug === 'webinars' && (
        <div className="video-overlay" style={{ visibility: 'hidden' }}>
          <div
            className={`wistia_embed wistia_async_${data.strapiResource.webinarWistiaId} popover=true popoverAnimateThumbnail=true popoverContent=link videoFoam=true`}
          >
            <a href="#" className="wistia-video-trigger">
              Play
            </a>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ResourceTemplate;

export const query = graphql`
  query ResourceTemplate($id: String!) {
    strapiResource(id: { eq: $id }) {
      id
      seo {
        excludeFromSitemap
        noFollow
        noIndex
        seoDescription
        seoTitle
      }
      title
      featuredImage {
        url
      }
      content
      resourceBanner {
        bannerImage {
          alternativeText
          url
          formats {
            large {
              url
            }
          }
        }
        bannerOverlay
        resourceSubTitle
        textColor
      }
      keyTakeaways {
        takeaway
      }
      webinarWistiaId
      downloadButtonLink
      downloadButtonText
      openNewTab
      gated
      formTitle
      form
      caseStudyModules
      resource_categories {
        name
        slug
      }
    }
  }
`;
